import {
  type ComboboxItem,
  type ComboboxProps,
  DefaultMantineColor,
  Group,
  Highlight,
  Text,
  TextProps,
  ThemeIcon,
} from '@mantine/core';
import { forwardRef } from 'react';
import { highlightStyles } from './Highlight';

type ExtendedSelectItem = ComboboxItem & {
  fw?: TextProps['fw'];
  color?: DefaultMantineColor;
  size?: ComboboxProps['size'];
  query?: string;
};

export const ColorDot: React.FC<{ color: DefaultMantineColor }> = ({ color }) => {
  if (!color) return null;
  return (
    <ThemeIcon size={'xs'} radius="xl" color={color}>
      {''}
    </ThemeIcon>
  );
};
export const SelectItemComponent = forwardRef<HTMLDivElement, ExtendedSelectItem>(
  ({ query, label, value, fw = 500, color = '', size = 'sm', ...others }: ExtendedSelectItem, ref) => {
    const displayedValue = label ?? value;
    return (
      <Group ref={ref} {...others}>
        <ColorDot color={color} />
        {query ? (
          <Highlight
            highlightStyles={highlightStyles}
            color={'violet.3'}
            fw={fw}
            highlight={query}
            size={size}
          >
            {displayedValue}
          </Highlight>
        ) : (
          <Text fw={fw} size={size}>
            {displayedValue}
          </Text>
        )}
      </Group>
    );
  },
);

SelectItemComponent.displayName = 'SelectItemComponent';
