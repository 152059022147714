import {
  CloseButton,
  Combobox,
  type ComboboxItem,
  Input,
  InputBase,
  Loader,
  ScrollArea,
  useCombobox,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayOptions } from '../shared/DisplayOptions';
import { Label } from '../shared/Label';
import { SelectItemComponent } from '../shared/SelectItemComponent';
import { parseFormErrorMessage } from '../shared/error-message.util';
import { selectUtils } from '../shared/select.utils';
import type { FormSelectProps } from './types';

import { useController, useFormContext } from 'react-hook-form';

export const FormSelect = ({
  name,
  icon,
  loading,
  description,
  label,
  size = 'sm',
  disabled,
  required = false,
  clearable,
  creatable,
  placeholder,
  itemComponent,
  searchable,
  onChange: handleChange,
  onSearchChange,
  data = [],
  miw,
  variant,
  hideError = false,
  fw,
  ...props
}: FormSelectProps) => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();
  const combobox = useCombobox({ onDropdownClose: () => combobox.resetSelectedOption() });
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<Record<string, string>>({
    name,
    control,
  });
  const [search, setSearch] = useState('');
  const filteredData = useMemo(
    () => (search ? data.filter((item) => selectUtils.filterFn(item, search)) : data),
    [search, data],
  );
  const selectedOption = value
    ? (data.find((item) => item.value === value) ?? { label: value, value })
    : null;
  const createLabel = useMemo<string>(() => t('Create {{search}}', { search }), [search]);
  const exactOptionMatch = data.some((item) => item.value === search);
  const ItemComponent = itemComponent ?? SelectItemComponent;
  const options = useMemo(
    () =>
      filteredData.map((item: ComboboxItem) => (
        <Combobox.Option value={item.value} key={item.value} disabled={item.disabled}>
          <ItemComponent query={search} {...item} />
        </Combobox.Option>
      )),
    [filteredData, search, ItemComponent],
  );
  const errorMessage = parseFormErrorMessage(error?.message);
  const isSearchable = searchable ?? data.length > 10;

  const iconToShow = useMemo(() => {
    if (loading) return <Loader size="sm" />;
    const displayIcon = icon ?? (searchable ? <IconSearch size={'1rem'} /> : undefined);
    return displayIcon;
  }, [loading, searchable, icon]);

  const showClear = clearable && Boolean(value);

  return (
    <Combobox
      width={'max-content'}
      position="bottom-start"
      store={combobox}
      onOptionSubmit={(val) => {
        if (val === '$create') {
          if (!creatable || !search) return;
          onChange(search);
          handleChange?.(search);
        } else {
          onChange(val);
          handleChange?.(val);
        }
        setSearch('');
        combobox.closeDropdown();
        onSearchChange?.('');
      }}
      {...props}
    >
      <Combobox.Target>
        <InputBase
          label={
            label ? <Label label={label} description={description} required={required} /> : undefined
          }
          styles={{ label: { width: '100%' } }}
          size={size}
          variant={variant}
          disabled={disabled}
          component="button"
          type="button"
          id={name}
          pointer
          error={hideError ? Boolean(errorMessage) : errorMessage}
          rightSection={
            showClear ? (
              <CloseButton
                size="xs"
                style={{ color: 'var(--mantine-color-dimmed)' }}
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  onChange(null);
                  combobox.resetSelectedOption();
                  handleChange?.('');
                }}
                aria-label="Clear value"
              />
            ) : (
              (iconToShow ?? <Combobox.Chevron />)
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={showClear ? 'all' : 'none'}
          multiline
          miw={miw}
        >
          {selectedOption ? (
            <ItemComponent size={size} fw={fw} query={search} {...selectedOption} />
          ) : (
            <Input.Placeholder>{placeholder ?? t('Select...')}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>
      <Combobox.Dropdown>
        {isSearchable && (
          <Combobox.Search
            value={search}
            data-test-id={`${name}-search`}
            onChange={(event) => {
              setSearch(event.currentTarget.value);
              onSearchChange?.(event.currentTarget.value);
            }}
            placeholder={t('Search...')}
          />
        )}

        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="auto">
            <DisplayOptions
              exactOptionMatch={exactOptionMatch}
              creatable={creatable}
              search={search}
              options={options}
              loading={loading}
              createLabel={createLabel}
            />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
